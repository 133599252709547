module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HFN Starter | Heartfulness.org","short_name":"HFNStarter","description":"HFN Starter is a meditation app that helps with relaxation, meditation, rejuvenation, introspection and deep reflection.","start_url":"/","background_color":"#9B7E29","theme_color":"#013B28","display":"standalone","icons":[{"src":"/img/logo-192.png","sizes":"192x192","type":"image/png"},{"src":"/img/heartfulness-white-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../gatsby-plugin-hfn-profile/gatsby-browser.js'),
      options: {"plugins":[],"pathToRootReducerModule":"./src/state/appReducer","firebaseAuthConfig":{"apiKey":"AIzaSyDvmxKH7738HE1T3fpJYUvi4BcEAakqQbQ","authDomain":"auth-qa.heartfulness.org","projectId":"unifiedplatform-qa"},"firebaseStoreConfig":{"apiKey":"AIzaSyCbz8YnmJaQnVTQy-EmftHux3SmLqi7134","authDomain":"hfn-registration-qa.firebaseapp.com","databaseURL":"https://hfn-registration-qa.firebaseio.com","projectId":"hfn-registration-qa","storageBucket":"hfn-registration-qa.appspot.com","messagingSenderId":"117921689659","appId":"1:117921689659:web:6791beccca0944602cec1e","measurementId":"G-F5P3LCLZGW"},"splashScreen":"loading..."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
