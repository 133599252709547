import fetchSrcmAPIDecompose from "./fetchSrcmAPIDecompose";

export const SESSION_EXPIRED_MESSAGE =
  "Session expired. Please refresh page and continue!";

const stringify = (v) => (typeof v === "string" ? v : JSON.stringify(v));
const transformErrorString = (apiError) =>
  Object.values(JSON.parse(apiError))
    .map((v) => (Array.isArray(v) ? v.join(", ") : stringify(v)))
    .join(", ");

//  const errorAsString = (apiError) => !apiError.startsWith("Error: ")
// ? apiError
// : transformErrorString(apiError.replace("Error: ", ""));

export default function fetchSrcmAPI(
  api,
  authToken = "",
  method = "GET",
  extraHdrs = {},
  extraData = {},
  forceAuth = false,
  methodParams = {},
  client = "baseClient"
) {
  const [url, data, retHandler] = fetchSrcmAPIDecompose(
    api,
    authToken,
    method,
    extraHdrs,
    extraData,
    forceAuth,
    methodParams,
    client
  );

  return fetch(url, data).then((res) => {
    if (!res.ok) {
      return res.text().then((text) => {
        if (
          text === '{"detail":"Authentication credentials were not provided."}'
        ) {
          throw new Error(SESSION_EXPIRED_MESSAGE);
        }
        throw new Error(transformErrorString(text));
      });
    }
    if (retHandler === "json") {
      return res.json();
    }
    if (retHandler === "text") {
      return res.text();
    }
    return res.json();
  });
}
